import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import IdCard from './components/Idcard';
import MergeImages from './components/MergeImages/MergeImages';
import Navbar from './components/Navbar';

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <>
      <Navbar></Navbar>
      <Routes>
        <Route path="/" element={<MergeImages />} />
        <Route path="/idCard" element={<IdCard />} />
      </Routes>
    </>
  );
}

export default App;
