import React from "react";
import { Link } from "react-router-dom";
import './Navbar.css'

const Navbar: React.FC = () => {
    return (
        <>
            <div style={{height: '80px', padding: '30px 40px', backgroundColor: '#F96C03' }}>
                <Link to={'/'}>Merge Image
                </Link>
                <Link to={'/idCard'}> Id card</Link>
            </div>
        </>
    )
}

export default Navbar