import React, { ChangeEvent } from 'react';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, FormGroup, FormText, Input, Label } from 'reactstrap';
import './IdCard.css';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const initialState = {
    id: 0,
    email: '',
    name: '',
    cardId: '',
    bloodGroup: ''
};



const IdCard = () => {

    const [userValue, setUserValue] = React.useState<any>(initialState);
    const [image, setImage] = React.useState('');
    const [isNameError, setNameError] = useState(false);
    const [NameErrorMsg, setNameErrorMsg] = useState('');

    const [isCardIdError, setCardIdError] = useState(false);
    const [CardIdErrorMsg, setCardIdErrorMsg] = useState('');

    const [isBloodGroupError, setBloodGroupError] = useState(false);
    const [BloodGroupErrorMsg, setBloodGroupErrorMsg] = useState('');

    const onFileChange = (e: any) => {
        const file = e.target.files[0];
        const reader: any = new FileReader();

        reader.onload = () => {
            setImage(reader.result.toString());
        };
        reader.readAsDataURL(file);
    };

    const onImageChange = (e: any) => {
        const { name, value } = e.target;
        setUserValue({
            ...userValue,
            [name]: value
        });
    };

    const validateName = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (value) {
            setNameError(false);
            setNameErrorMsg('');
        } else {
            setNameError(true);
            setNameErrorMsg('Custom Field Name is required');
        }
    };

    const handleDownload = () => {
        debugger
        const input = document.getElementById('id-card')!;
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const width = pdf.internal.pageSize.getWidth();
            const height = pdf.internal.pageSize.getHeight();
            // canvas.width = input.offsetWidth;
            // canvas.height = input.offsetHeight;
            // pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
            pdf.addImage(imgData, 'PNG', 0, 0, width, height);
            pdf.save('id-card.pdf');
        });

        setUserValue(initialState);
        setImage('');
    }

    

    // const handleDownload = () => {
    //     const input = document.getElementById('id-card')!;
    //     const canvas = document.createElement('canvas');
    //     const context = canvas.getContext('2d')!;
    //     const scale = 2; // Adjust scale as needed
    
    //     // Set canvas size to match the element
    //     canvas.width = input.offsetWidth * scale;
    //     canvas.height = input.offsetHeight * scale;
    
    //     // Scale the context to match the scale
    //     context.scale(scale, scale);
    
    //     // Draw content onto the canvas
    //     html2canvas(input, { scale: scale, canvas: canvas }).then(() => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF();
    //         pdf.addImage(imgData, 'PNG', 0, 0, canvas.width / scale, canvas.height / scale);
    //         pdf.save('id-card.pdf');
    //     });
    
    //     // Reset user value and image after download
    //     setUserValue(initialState);
    //     setImage('');
    // };
    
    const validateCardId = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (value) {
            setCardIdError(false);
            setCardIdErrorMsg('');
        } else {
            setCardIdError(true);
            setCardIdErrorMsg('Custom Field Name is required');
        }
    };

    const validateBloodGroup = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (value) {
            setBloodGroupError(false);
            setBloodGroupErrorMsg('');
        } else {
            setBloodGroupError(true);
            setBloodGroupErrorMsg('Custom Field Name is required');
        }
    };

    const handleInputChange = (arr: any) => {
        const { name, value } = arr.target;
        setUserValue({ ...userValue, [name]: value });

    };


    return (
        <div style={{ height: 'calc(100vh - 80px)', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <form>
                <div style={{
                    width: '22rem',
                    // background: 'radial-gradient(circle, rgba(249,108,3,4) 0%, rgba(249,203,13,1) 100%)',
                    // backgroundImage: 'linear-gradient(to right top, #f96c03, #fb7f00, #fc9200, #fda400, #fcb500, #f1a419, #e49325, #d6842c, #aa5934, #753730, #3c1e21, #000000)'
                    backgroundColor: "#fff",

                }}>
                    <Card id="id-card"
                        style={{
                            background: "transparent",
                            border: "2px solid #F96C03",
                            borderRadius: "0px"
                        }}
                    >
                        {/* <img
                    alt="Sample"
                    src="https://picsum.photos/300/200"
                /> */}
                        <CardBody>



                            <FormGroup
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '10px'
                                }}
                            >
                                <input
                                    style={{
                                        display: 'none'
                                    }}
                                    id="icon-button-file"
                                    type="file"
                                    accept="image/*"
                                    name="image"
                                    // value = {userValue.image}
                                    onChange={(e) => {
                                        onFileChange(e);
                                        onImageChange(e);
                                    }}
                                    className="upload-button"
                                />
                                <label htmlFor="icon-button-file">
                                    {image ? (
                                        <img
                                            src={image}
                                            alt="userProfile"
                                            style={{
                                                height: '100px',
                                                width: '100px',
                                                borderRadius: '50%',
                                            }}
                                        />
                                    ) : (
                                        <img
                                            src="/userLogo.png"
                                            alt="userProfile"
                                            style={{
                                                height: '100px',
                                                width: '100px',
                                                borderRadius: '50%',
                                            }}
                                        />
                                    )}
                                </label>
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Name
                                </Label>
                                <Input
                                    id="exampleEmail"
                                    name="name"
                                    type="email"
                                    value={userValue.name}
                                    onChange={(e: any) => {
                                        validateName(e);
                                        handleInputChange(e);
                                    }}
                                    required={true}
                                />
                                <FormText style={{ color: 'red' }}>
                                    {isNameError && NameErrorMsg}
                                </FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleId">
                                    Card Id
                                </Label>
                                <Input
                                    id="exampleId"
                                    name="cardId"
                                    type="text"
                                    value={userValue.cardId}
                                    onChange={(e: any) => {
                                        validateCardId(e);
                                        handleInputChange(e);
                                    }}
                                    required={true}
                                />
                                <FormText style={{ color: 'red' }}>
                                    {isCardIdError && CardIdErrorMsg}
                                </FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleBloodGroup">
                                    Blood group
                                </Label>
                                <Input
                                    id="exampleBloodGroup"
                                    name="bloodGroup"
                                    type="text"
                                    value={userValue.bloodGroup}
                                    onChange={(e: any) => {
                                        validateBloodGroup(e);
                                        handleInputChange(e);
                                    }}
                                    required={true}
                                />
                                <FormText style={{ color: 'red' }}>
                                    {isBloodGroupError && BloodGroupErrorMsg}
                                </FormText>
                            </FormGroup>


                        </CardBody>
                    </Card></div>
                <div style={{ textAlign: "end", marginTop: '15px' }}>
                    <Button onClick={handleDownload}>Download</Button>
                </div>
            </form>
        </div>
    );
};

export default IdCard;
