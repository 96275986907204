import React, { ChangeEvent, useEffect, useRef } from 'react';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, FormGroup, FormText, Input, Label } from 'reactstrap';
// import './IdCard.css';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';




const initialState = {
    id: 0,
    email: '',
    name: '',
    cardId: '',
    bloodGroup: ''
};



const MergeImages = () => {

    const [userValue, setUserValue] = React.useState<any>(initialState);
    const [image1, setImage1] = React.useState('');
    const [image2, setImage2] = React.useState('');
    const [finalImage, setFinalImage] = React.useState('');
    const [isNameError, setNameError] = useState(false);
    const [NameErrorMsg, setNameErrorMsg] = useState('');

    const [isCardIdError, setCardIdError] = useState(false);
    const [CardIdErrorMsg, setCardIdErrorMsg] = useState('');

    const [isBloodGroupError, setBloodGroupError] = useState(false);
    const [BloodGroupErrorMsg, setBloodGroupErrorMsg] = useState('');

    const image1Ref = useRef(null);
    const image2Ref = useRef(null);
    const canvasRef = useRef(null);

    const onFirstFileChange = (e: any) => {
        const file = e.target.files[0];
        const reader: any = new FileReader();
        reader.onload = () => {
            setImage1(reader.result.toString());
        };
        reader.readAsDataURL(file);
    };

    const onSecondFileChange = (e: any) => {
        const file = e.target.files[0];
        const reader: any = new FileReader();
        reader.onload = () => {
            setImage2(reader.result.toString());
        };
        reader.readAsDataURL(file);
    };

    const onImageChange = (e: any) => {
        debugger
        const { name, value } = e.target;
        setUserValue({
            ...userValue,
            [name]: value
        });
    };
    const mergeImages = (event: any) => {
        debugger
        event.preventDefault();
        if (!image1 || !image2) {
            // If either image is not selected, return early
            return;
        }

        const canvas: any = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const image1Element = new Image();
        image1Element.onload = () => {
            // Set canvas size
            canvas.width = image1Element.width;
            canvas.height = image1Element.height;

            // Draw images onto canvas
            ctx.drawImage(image1Element, 0, 0);
            ctx.drawImage(image2Element, 0, 0);

            // Apply background color with opacity
            // ctx.fillStyle = hexToRgbA(userValue.backgroundColor, 0.5); // Adjust opacity as needed
            // ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Get merged image data URL
            const mergedImageURL = canvas.toDataURL();

            // Display merged image
            setFinalImage(mergedImageURL);
            console.log(finalImage)
        };
        image1Element.src = image1;

        const image2Element = new Image();
        image2Element.src = image2;
    };

    // const mergeImages = (event: any) => {
    //     debugger
    //     event.preventDefault()
    //     if (!image1 || !image2) {
    //         // If either image is not selected, return early
    //         return;
    //     }

    //     const canvas: any = canvasRef.current;
    //     const ctx = canvas.getContext('2d');

    //     const image1Element = new Image();
    //     image1Element.onload = () => {
    //         // Set canvas size
    //         canvas.width = image1Element.width;
    //         canvas.height = image1Element.height;

    //         // Draw images onto canvas
    //         ctx.drawImage(image1Element, 0, 0);
    //         ctx.drawImage(image2Element, 0, 0);

    //         // Get merged image data URL
    //         const mergedImageURL = canvas.toDataURL();

    //         // Display merged image
    //         const mergedImage = document.createElement('img');
    //         mergedImage.src = mergedImageURL;
    //         document.body.appendChild(mergedImage);
    //         setFinalImage(mergedImageURL);
    //         console.log(finalImage)
    //     };
    //     image1Element.src = image1;

    //     const image2Element = new Image();
    //     image2Element.src = image2;
    // };

    // function EditableMergedImage({ finalImage }: any) {
    //     const canvasRef = useRef(null);
    //     const [backgroundColor, setBackgroundColor] = useState('#ffffff'); // Initial background color

    //     useEffect(() => {
    //         const canvas: any = canvasRef.current;
    //         const ctx = canvas.getContext('2d');

    //         const image = new Image();
    //         image.onload = () => {
    //             // Set canvas size to match image size
    //             canvas.width = image.width;
    //             canvas.height = image.height;

    //             // Draw merged image
    //             ctx.clearRect(0, 0, canvas.width, canvas.height);
    //             ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    //             // Fill background
    //             ctx.fillStyle = backgroundColor;
    //             ctx.fillRect(0, 0, canvas.width, canvas.height);
    //         };
    //         image.src = finalImage;
    //     }, [finalImage, backgroundColor]);

    //     const handleBackgroundColorChange = (e: any) => {
    //         setBackgroundColor(e.target.value);
    //     };

    //     const handleDownload = () => {
    //         const canvas: any = canvasRef.current;
    //         const downloadLink = document.createElement('a');
    //         const tempCanvas = document.createElement('canvas');
    //         const tempCtx :any = tempCanvas.getContext('2d');
    //         tempCanvas.width = canvas.width;
    //         tempCanvas.height = canvas.height;

    //         // Draw merged image
    //         tempCtx.drawImage(canvas, 0, 0);

    //         // Fill background
    //         tempCtx.fillStyle = backgroundColor;
    //         tempCtx.fillRect(0, 0, canvas.width, canvas.height);

    //         downloadLink.href = tempCanvas.toDataURL('image/png');
    //         downloadLink.download = 'edited_image.png';
    //         downloadLink.click();
    //       };

    //     return (
    //         <div>
    //             <canvas ref={canvasRef} />
    //             <input type="color" value={backgroundColor} onChange={handleBackgroundColorChange} />
    //             <button onClick={handleDownload}>Download Edited Image</button>
    //         </div>
    //     );
    // }

    const handleBackgroundColorChange = (e: any) => {
        setUserValue({
            ...userValue,
            backgroundColor: e.target.value
        });
    };

    // const handleDownload = () => {
    //     // Create a new canvas element
    //     const canvas = document.createElement('canvas');
    //     const ctx: any = canvas.getContext('2d');

    //     // Set canvas size to match the image size
    //     const img = new Image();
    //     img.onload = () => {
    //         canvas.width = img.width;
    //         canvas.height = img.height;

    //         // Draw image with background color onto the canvas
    //         ctx.fillStyle = userValue.backgroundColor;
    //         ctx.fillRect(0, 0, canvas.width, canvas.height);
    //         ctx.drawImage(img, 0, 0);

    //         // Convert canvas content to a data URL
    //         const dataURL = canvas.toDataURL();

    //         // Create a temporary link element and trigger the download
    //         const downloadLink = document.createElement('a');
    //         downloadLink.href = dataURL;
    //         downloadLink.download = 'merged_image_with_background.png';
    //         downloadLink.click();
    //     };
    //     img.src = finalImage;
    // };

    const handleDownload = () => {
        // Create a new canvas element
        const canvas = document.createElement('canvas');
        const ctx: any = canvas.getContext('2d');

        // Set canvas size to match the image size
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw merged images onto the canvas
            ctx.drawImage(img, 0, 0);

            // Fill the canvas with the background color
            ctx.fillStyle = userValue.backgroundColor;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Convert canvas content to a data URL
            const dataURL = canvas.toDataURL();

            // Create a temporary link element and trigger the download
            const downloadLink = document.createElement('a');
            downloadLink.href = dataURL;
            downloadLink.download = 'merged_image_with_background.png';
            downloadLink.click();
        };
        img.src = finalImage;
        console.log(img.src)
    };


    const handleDownload1 = () => {
        // Select the element containing the image and background color
        const input = document.getElementById('final-image')!;

        // Capture the HTML content and convert it to a canvas
        html2canvas(input).then((canvas) => {
            // Convert the canvas content to a data URL
            const imgData = canvas.toDataURL('image/png');

            // Create a temporary link element and trigger the download
            const downloadLink = document.createElement('a');
            downloadLink.href = imgData;
            downloadLink.download = 'final_image_with_background.png';
            downloadLink.click();
        });
        setImage1('');
        setImage2('');
        setFinalImage('');
    };



    return (
        <div style={{ height: 'calc(100vh - 80px)', width: '100vw', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', overflowX: "hidden" }}>
            <form onSubmit={mergeImages}>
                <Card id="id-card"
                    style={{
                        width: '22rem',
                        border: '0px'
                    }}
                >

                    <div style={{ textAlign: "center", fontSize: '1.5rem', fontWeight: '600', marginBottom:"20px", color:"#3b3b3b" }}>Select images to merge.</div>
                    <CardBody style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>

                        <div>

                            <FormGroup
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '10px'
                                }}
                            >
                                <input
                                    style={{
                                        display: "none"
                                    }}
                                    id="image1"
                                    type="file"
                                    accept="image/*"
                                    name="image1"
                                    // value = {userValue.image}
                                    onChange={(e) => {
                                        onFirstFileChange(e);
                                        onImageChange(e);
                                    }}
                                    className="upload-button"
                                    ref={image1Ref}
                                />
                                <label htmlFor="image1" style={{ cursor: "pointer" }}>
                                    {image1 ? (
                                        <img
                                            src={image1}
                                            alt="userProfile"
                                            style={{
                                                height: '200px',
                                                width: '200px',
                                                // borderRadius: '50%',
                                            }}
                                        // onClick={() => image1Ref?.current?.click()}
                                        />
                                    ) : (
                                        // <img
                                        //     src="/userLogo.png"
                                        //     alt="userProfile"
                                        //     style={{
                                        //         height: '200px',
                                        //         width: '200px',
                                        //         // borderRadius: '50%',
                                        //     }}
                                        // />

                                        <div className='upload-file' style={{ height: "200px", width: "200px", backgroundColor: "#fff"}}>
                                            <div style={{ color: "#F96C03", textAlign: "center", border: "1px dashed", padding: "45px 10px", borderRadius: "10px" }}><i className="fa-solid fa-file-arrow-up" style={{ fontSize: "3.5rem" }}></i>
                                                <div>Click to upload image</div></div>

                                        </div>
                                    )}

                                </label>
                            </FormGroup>
                        </div>

                        <div>
                            <FormGroup
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '10px'
                                }}
                            >
                                <input
                                    style={{
                                        display: "none"
                                    }}
                                    id="image2"
                                    type="file"
                                    accept="image/*"
                                    name="image2"
                                    // value = {userValue.image}
                                    onChange={(e) => {
                                        onSecondFileChange(e);
                                        onImageChange(e);
                                    }}
                                    className="upload-button"
                                    ref={image2Ref}
                                />
                                <label htmlFor="image2" style={{ cursor: "pointer" }}>
                                    {image2 ? (
                                        <img
                                            src={image2}
                                            alt="userProfile"
                                            style={{
                                                height: '200px',
                                                width: '200px',
                                                // borderRadius: '50%',
                                            }}
                                        // onClick={() => image2Ref?.current?.click()}
                                        />
                                    ) : (
                                        // <img
                                        //     src="/userLogo.png"
                                        //     alt="userProfile"
                                        //     style={{
                                        //         height: '200px',
                                        //         width: '200px',
                                        //         // borderRadius: '50%',
                                        //     }}
                                        // />
                                        <div className='upload-file' style={{ height: "200px", width: "200px", backgroundColor: "#fff" }}>
                                            <div style={{ color: "#F96C03", textAlign: "center", border: "1px dashed", padding: "45px 10px", borderRadius: "10px" }}><i className="fa-solid fa-file-arrow-up" style={{ fontSize: "3.5rem" }}></i>
                                                <div>Click to upload image</div></div>

                                        </div>
                                    )}
                                </label>
                            </FormGroup>
                        </div>



                    </CardBody>
                </Card>
                <div style={{ textAlign: "center", marginTop: '15px' }}>
                    {/* <Button onClick={handleDownload}>Download</Button> */}
                    <Button type="submit" style={{ marginBottom: '20px' }}>Merge Images</Button>


                    <canvas ref={canvasRef} style={{ display: "none" }} />
                </div>
            </form>

            {/* <img
                    id="final-image"
                    src={finalImage}
                    alt="userProfile"
                    style={{
                        height: '300px',
                        width: '300px',
                    }}
                />
                <div style={{ textAlign: "end", marginTop: '15px' }}>
                    <a href={finalImage} download="merged_image.png">
                        <button>Download</button>
                    </a>
                </div> */}
            {finalImage && (
                <div>
                    <div>
                        <img
                            id="final-image"
                            src={finalImage}
                            alt="userProfile"
                            style={{
                                height:"600px",
                                width: '750px',
                                padding: '20px',
                                backgroundColor: userValue.backgroundColor
                            }}
                        />
                    </div>
                    <div>
                        {/* <label htmlFor="editImage">Click here to edit background</label> */}
                        {/* <input
                            id="editImage"
                            type="color"
                            value={userValue.backgroundColor}
                            onChange={handleBackgroundColorChange}
                            style={{ display: "none" }}
                        /> */}
                    </div>
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                        <label htmlFor="editImage" style={{ cursor: "pointer", marginRight: '10px', border:"1px solid" , padding:"5px 9px", color:"#3b6e8f" }}> <i className="fas fa-pencil"></i> </label>
                        <input
                            id="editImage"
                            type="color"
                            value={userValue.backgroundColor}
                            onChange={handleBackgroundColorChange}
                            style={{ display: "none" }}
                        />

                        <Button onClick={handleDownload1}>Download</Button></div>
                </div>

            )}
            {/* <div style={{ textAlign: "end", marginTop: '15px' }}>
                    <a href={finalImage} download="merged_image.png">
                        <button>Download</button>
                    </a>
                </div> */}


            {/* <div>
                    <h1>Edit Merged Image Background</h1>
                    <EditableMergedImage finalImage={finalImage} />
                </div> */}

        </div>
    );
};

export default MergeImages;
